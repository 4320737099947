import { useShortAnswerInput } from 'react-google-forms-hooks'
import TextField from '@mui/material/TextField';

export default function ShortAnswerInput({ id, value, required }) {
  const { register, label } = useShortAnswerInput(id)
  const readOnly = value ? true : false

  return (
    <>
      <TextField required={required} label={label} {...register()} defaultValue={value} InputProps={{readOnly: readOnly}} style={{width:500}}/>
    </>
  )
}
