import QRCode from "react-qr-code";
import Button from '@mui/material/Button';

export default function QRCodeComponent(props) {
  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }

  return (
    <div style={{ height: "auto", margin: "0 auto", maxWidth: 300, width: "100%" , background: 'white', padding: '16px'  }}>
      <h1>請存下此QR code，報到時出示。</h1>
			<QRCode
        id="QRCode"
				size={256}
				style={{ height: "auto", maxWidth: "100%", width: "100%", marginBottom:"20px"}}
				value={props.address}
				viewBox={`0 0 256 256`}
			/> 
      <Button variant="contained" onClick={onImageDownload}>Download QR Code</Button>
    </div>
  )
}
