import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components'

import form from '../../scripts/form.json'
import ShortAnswerInput from './ShortAnswerInput'
import RadioInput from './RadioInput'

import { useState } from 'react'

const Form = styled.form`
  margin: 0 auto;
  padding: 50px 0;
`

const QuestionContainer = styled.div`
  margin-bottom: 20px;
`

const QuestionHelp = styled.p`
  font-size: 0.8rem;
  color: #5c5c5c;
  margin-top: 0px;
`
const OptionalQuestions = (props) => {
  return (
    <div style={{display:'flex', gap:props.more ? '20px' : '0px', justifyContent: 'center', flexWrap: 'wrap'}}>
    {
      props.dogs.map((dog, index) => {
        return (
          <div>
          {
            form.fields.map((field) => {
              const { id } = field
              if (field.label.indexOf(index+1) <= -1) return null
              if (field.label.indexOf('名牌想印的狗狗ID') > -1 && props.dogs.length <= 1) return null
              if (index > 0 && !props.more) return null
              return(
                <QuestionContainer key={id}>
                  <ShortAnswerInput id={id} required={field.required}/>
                  <QuestionHelp>{field.description}</QuestionHelp>
                </QuestionContainer>
              )
            })
          }
          </div>
        )
      })
    }
    </div>
  )
}

const Questions = (props) => {
  return (
    <div>
      {form.fields.map((field) => {
        const { id } = field

        let questionInput = null

        if (field.required) {
          switch (field.type) {
            case 'SHORT_ANSWER':
              switch(field.label) {
                case '錢包地址':
                  questionInput = <ShortAnswerInput id={id} value={props.address}/>
                  break
                case '持有狗狗編號':
                  if (props.dogs.length > 0) {
                    questionInput = <ShortAnswerInput id={id} value={props.dogs}/>
                  }
                  break
                default:
                  questionInput = null
              }
              break
            default:
              break
          }
        }

        if (!questionInput) {
          return null
        }

        return (
          <QuestionContainer key={id}>
            {questionInput}
            <QuestionHelp>{field.description}</QuestionHelp>
          </QuestionContainer>
        )
      })}
    </div>
  )
}

const GoogleForm = (props) => {
  const [more, setMore] = useState(false)
  const methods = useGoogleForm({ form })
  const onSubmit = async (data) => {
    console.log(data)
    await methods.submitToGoogleForms(data)
    props.setStage(2)
  }

  const handleChange = () => {
    setMore(!more)
  }

  return (
    <GoogleFormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
				{form.title && (
          <>
            <h1>{form.title}</h1>
            {form.description && (
              <p style={{ fontSize: '.8rem' }}>{form.description}</p>
            )}
          </>
        )}
        <Questions address={props.address} dogs={props.dogs}/>
        <OptionalQuestions address={props.address} dogs={props.dogs} more={more}/>
        { props.dogs.length > 1 && <FormControlLabel control={<Switch />} label="我有超過一個人想參加!" checked={more} onChange={handleChange}/>}
        { props.dogs.length > 0 && <div><RadioInput id={"1278563883"} /></div>}
        {
          props.dogs.length > 0 
          ? <Button variant="contained" type='submit'>Submit</Button>
          : <div>
              <div>本場論壇僅提供給FOMO Dog持有者報名</div>
              <div>欲加入FOMO Dog Club，請至<a href="https://opensea.io/collection/fomo-dog-club">官方Opensea</a></div>
            </div>

        }
      </Form>
    </GoogleFormProvider>
  )
}

export default GoogleForm
