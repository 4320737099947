import { useRadioInput } from 'react-google-forms-hooks'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function RadioInput({ id }) {
  const { options, label, error } = useRadioInput(id)

  return (
    <FormControl error={error}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup>
      {options.map((o, index) => (
        <FormControlLabel key={index} {...o.registerOption()} control={<Radio />} label={o.label} />
      ))}
      </RadioGroup>
      {error && <FormHelperText>Please select an option</FormHelperText>}
    </FormControl>
  )
}
