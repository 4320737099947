import Web3Modal from './components/Web3Modal'
import GoogleForm from './components/GoogleForm'
import QRCode from './components/QRCode'
import { useState, useEffect } from 'react'
import './App.css'

function App() {
  const [stage, setStage] = useState(0)
  const [provider, setProvider] = useState()
  const [account, setAccount] = useState()
  const [dogs, setDogs] = useState([])
  const [contract, setContract] = useState()

  useEffect(() => {
	  if (provider?.on) {
      const handleAccountsChanged = async (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          if (contract) {
            let dogs = await contract.getFomoDogByOwner(accounts[0])
            dogs = dogs.map(dog => dog.toNumber())
            setDogs(dogs)
          }
          return
        }
        setAccount()
        setDogs([])
        setStage(0)
      };

      provider.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
    
  }, [provider, contract])
  const RenderSwitch = () => {
    let component = null
    switch (stage) {
      case 0:
        component = 
          <Web3Modal 
            setStage={setStage} 
            setProvider={setProvider}
            setAccount={setAccount}
            setDogs={setDogs}
            setContract={setContract}
          />
        break
      case 1:
        component = 
          <GoogleForm 
            setStage={setStage}
            address={account}
            dogs={dogs}
          />
        break
      case 2:
        component = <QRCode address={account}/>
        break
      default:
        component =
          <Web3Modal 
            setStage={setStage} 
            setProvider={setProvider}
            setAccount={setAccount}
            setDogs={setDogs}
            setContract={setContract}
          />
        break
    }
    return component
  }
  return (
    <div className="App">
      <header className="App-header">
        <RenderSwitch />
      </header>
    </div>
  );
}

export default App;
