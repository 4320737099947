import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";
import Button from '@mui/material/Button';
import abi from '../../contract/fomodog-abi.json'

const web3Modal = new Web3Modal({
  providerOptions // required
});

export default function Web3ModalComponent(props) {
	const connectWallet = async () => {
		const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();

    const contract = new ethers.Contract('0x90cfce78f5ed32f9490fd265d16c77a8b5320bd4', abi, library)
    let dogs = await contract.getFomoDogByOwner(accounts[0])
    dogs = dogs.map(dog => dog.toNumber())

    if (dogs.length > 0) props.setDogs(dogs)
    if (accounts) props.setAccount(accounts[0]);
    props.setProvider(provider)
    props.setContract(contract)
    props.setStage(1)
  };
  return (
    <>
      <h1>2022-10-23</h1>
      <h1>追高狗週年論壇</h1>
      <div style={{marginBottom: '40px', color:'gray'}}>點擊「Connect」開始報名</div>
      <Button variant="contained" onClick={connectWallet}>Connect</Button>
    </>
  )
}
